import Application from "Application";
import LeagueCards from "components/icons/leagues-card";
import { HUNDRED, ZERO } from "config/_const";
import { TournamentLeagues } from "config/_enums";
import { TournamentLeagueSvg } from "data/TournamentImages/tournamentImages.data";
import ITournamentLeague from "model/Tournament/ITournamentLeague";

export default class LeagueViewModel {
    private index: number;
    private name: TournamentLeagues;
    private totalTokens: number;
    private minWalletShares: number;
    private leagueTokens: number;
    private maxSubscriptions: number;
    private cashprizeChunk: number;
    private maxMultiplierBetweenFirstAndLast: number;
    private level: number;
    private headerColorPrimary: string;
    private headerColorSecondary: string;
    private headerColorTertiary: string;
    private btnColor: string;
    private btnColorSecondary: string;
    private prizePool: number;
    private priceForTeamScoreBoost: number;
    private pieceIconLink: string;
    private discount: number;
    private minSubscriptions: number;
    private defaultLeaguePrice: number;

    constructor(league: ITournamentLeague) {
        this.index = league.index;
        this.name = league.name;
        this.totalTokens = league.minWalletShares.addition(league.leagueTokens);
        this.minWalletShares = league.minWalletShares;
        this.leagueTokens = league.leagueTokens;
        this.maxSubscriptions = league.maxSubscriptions;
        this.cashprizeChunk = league.cashprizeChunk;
        this.maxMultiplierBetweenFirstAndLast = league.maxMultiplierBetweenFirstAndLast;
        this.level = league.level;
        this.headerColorPrimary = league.headerColorPrimary;
        this.headerColorSecondary = league.headerColorSecondary;
        this.headerColorTertiary = league.headerColorTertiary;
        this.btnColor = league.btnColor;
        this.btnColorSecondary = league.btnColorSecondary;
        this.prizePool = league.prizePool
        this.priceForTeamScoreBoost = league.priceForTeamScoreBoost;
        this.pieceIconLink = league.pieceIconLink;
        this.discount = league.discount;
        this.minSubscriptions = league.minSubscriptions;
        this.defaultLeaguePrice = league.defaultLeaguePrice;
    }

    public get Index(): number {
        return this.index;
    }

    public get TotalTokens(): number {
        return this.totalTokens;
    }

    public get Name(): TournamentLeagues {
        return this.name
    }

    public get MinWalletShares(): number {
        return this.minWalletShares
    }

    public get LeagueTokens(): number {
        return this.leagueTokens;
    }

    public get MaxSubscriptions(): number {
        return this.maxSubscriptions;
    }

    public get CashprizeChunk(): number {
        return this.cashprizeChunk;
    }

    public get EstimateYearlyPayouts(): number {
        return this.PrizePool.multiply(Application.getInstance().tournamentsPerYear);
    }

    public get MaxMultiplierBetweenFirstAndLast(): number {
        return this.maxMultiplierBetweenFirstAndLast;
    }

    public get Level(): number {
        return this.level;
    }

    public get BtnColor(): string {
        return this.btnColor;
    }

    public get BtnColorSecondary(): string {
        return this.btnColorSecondary;
    }

    public get HeaderColorPrimary(): string {
        return this.headerColorPrimary;
    }

    public get PrizePool(): number {
        return this.prizePool
    }

    public get PrizePoolToCurrency(): string {
        return this.PrizePool.toCurrency();
    }

    public get EstimateYearlyPayoutsToCurrency(): string {
        return this.EstimateYearlyPayouts.toCurrency();
    }

    public get IconLink(): string {
        return TournamentLeagueSvg[this.Name];
    }

    public get TournamentLeagueBannerLink(): string {
        return `/img/tournament/background/${this.Name}.png`;
    }

    public get CardIconLink(): string {
        return LeagueCards[this.Name];
    }
    public get PriceForTeamScoreBoost(): number {
        return this.priceForTeamScoreBoost;
    }

    public getAccessConditions(): LeagueViewModel[] {
        const leagues: LeagueViewModel[] = Application.getInstance().getLeagues();
        const leagueIndex: number = leagues.findIndex((league: LeagueViewModel) => league.Name === this.Name);
        if (leagueIndex === -1)
            return [];
        const leaguesStart: LeagueViewModel[] = leagues.slice(leagueIndex, leagues.length);
        return leaguesStart.reduce<LeagueViewModel[]>((requireLeagues: LeagueViewModel[], league: LeagueViewModel) => {
            if (league.LeagueTokens === ZERO)
                return requireLeagues;
            requireLeagues.push(league);
            return requireLeagues;
        }, []);
    }

    public getYearlyCashPrize(tournamentPrizePool: number): number {
        return tournamentPrizePool * this.CashprizeChunk * Application.getInstance().tournamentsPerYear;
    }

    public getYearlyCashPrizeToCurrency(tournamentPrizePool: number): string {
        return this.getYearlyCashPrize(tournamentPrizePool).toCurrency(2);
    }

    public getROI(tournamentPrizePool: number): number {
        return (this.getYearlyCashPrize(tournamentPrizePool) / (this.MinWalletShares * Application.getInstance().averageSharePrice)) * HUNDRED;
    }

    public getROIWithFixedDecimals(tournamentPrizePool: number, fractionDigits: number = 2): string {
        return this.getROI(tournamentPrizePool).toFixed(fractionDigits);
    }

    public getGradientBackground(degrees: number = 180): string {
        return `linear-gradient(${degrees}deg, ${this.headerColorPrimary} 0%, ${this.headerColorSecondary} 49%, ${this.headerColorTertiary} 100%)`;
    }

    public get PieceIconLink(): string {
        return this.pieceIconLink;
    }

    public get Discount(): number {
        return this.discount;
    }

    public get DiscountAsDisplay(): number {
        return this.Discount * 100;
    }

    public get MinSubscriptions(): number {
        return this.minSubscriptions;
    }

    public getLeagueCashprizeBySubscriptions(totalSubscriptions: number): number {
        const percentageOfMaxCashprize: number = totalSubscriptions.divide(this.MinSubscriptions);
        return Math.min(this.PrizePool.multiply(percentageOfMaxCashprize), this.PrizePool);
    }

    public getLeagueCashprizeBySubscriptionsAsCurrency(totalSubscriptions: number): string {
        return this.getLeagueCashprizeBySubscriptions(totalSubscriptions).toCurrency();
    }

    public get DefaultLeaguePrice(): number {
        return this.defaultLeaguePrice;
    }
}