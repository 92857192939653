import i18n from '18n';
import { RemoveCircle } from '@material-ui/icons';
import clsx from 'clsx';
import WhiteButton from 'components/small/buttons/white-button/WhiteButton';
import ScoreLabel from 'components/small/score-label/ScoreLabel';
import useAssetSlideView from 'hooks/asset/use-asset-slide-view/useAssetSlideView';
import UseTeamConfigRespectToUserLeague from 'hooks/team/use-team-config-respect-to-user-league/UseTeamConfigRespectToUserLeague';
import React from 'react';
import Color from '../../../../view-model/Shared/Color';
import IFilledSlotProps from './IFilledSlotProps';
import './style.scss';


const FilledSlot: React.FC<IFilledSlotProps> = (props: IFilledSlotProps) => {
    const { onAssetProfileClick } = useAssetSlideView();
    const { assetViewModel } = props;
    const { minSharesPerSlot } = UseTeamConfigRespectToUserLeague();
    const equivalentCard = assetViewModel.getTeamCardEquivalence(props.numberOfShares, minSharesPerSlot);

    return (
        <div className="filled-slot">
            {props.showRemoveIcon &&
                <div className="hover remove"
                    onClick={() => {
                        props.onRemove && props.onRemove();
                    }}
                >
                    <RemoveCircle className="icon" />
                </div>
            }

            <div className={clsx("filled-slot-background", { 'opacity': props.blurEffect })} style={{ background: props.borderColor }} onClick={() => onAssetProfileClick(assetViewModel)}>
                <div className={clsx("filled-slot-container")}>
                    <div>
                        <img loading="lazy" className="background" src={props.backgroundImgPath} alt="Background" />
                        <div className={clsx('filter', props.hasFocus && 'focus-border')} />
                    </div>
                    <div className='asset-score-container'>
                        {props.canShowTrendScore &&
                            <ScoreLabel
                                score={assetViewModel.getTrendScoreToDisplay(2)}
                                backgroundColor={Color.getColorByInputRatio(assetViewModel.IRLScore || 0)}
                                canShowBoost={assetViewModel.ScoreBoosted}
                                tooltipText={assetViewModel.getScoreTooltipText(i18n.t('tooltip.trend.score'))}
                            />
                        }

                        {props.canShowSportScore &&
                            <ScoreLabel
                                category={props.category}
                                score={props.assetViewModel.getSportScoreToDisplay(2)}
                                backgroundColor={Color.getColorByInputRatio(props.assetViewModel.SportScore || 0)}
                                canShowBoost={assetViewModel.ScoreBoosted}
                                tooltipText={assetViewModel.getScoreTooltipText(i18n.t('tooltip.sport.score'))}
                            />
                        }
                    </div>
                    <img src={assetViewModel.AvatarPath} className="grid-asset-avatar-img" alt={assetViewModel.AvatarPath} loading="lazy" />
                    <div className='overlay-filter'>
                        <div className="container-card">
                            <p className="no-margin label">{assetViewModel.AssetName}</p>
                            <div className="shares-container">
                                <span className="no-margin label">X</span>
                                <span className="no-margin shares">{props.numberOfShares}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {props.showTokenPerSlot &&
                <div className={clsx('filled-slot__token-per-slot', {
                    'valid-slot': equivalentCard.isCountAcceptable,
                    'invalid-slot': !equivalentCard.isCountAcceptable
                })}>
                    {equivalentCard.totalCount}/{minSharesPerSlot}
                </div>
            }

            {props.showGetButton &&
                <div className="button-get">
                    <WhiteButton
                        startIcon={props.iconButton}
                        disabled={false}
                        receiveClickEvent={false}
                        handleClick={props.onSlotSelected}
                        type="button"
                        textButton={assetViewModel.getTeamGetBtnTxt(props.textButton, props.numberOfShares)}
                        debounceEvent={true}
                        preventDefaultEvent={false} />
                </div>
            }
        </div>
    );
};

export default React.memo(FilledSlot);
