import ITransactionInterface from 'model/Transaction/ITransactionInterface';
import { IAssetInAssetTransaction } from './../../model/AssetTransactions/IAssetTransaction';
import platform from "assets/images/wallet-transactions/platform.png";
import credit_card from "assets/images/wallet-transactions/credit_card.png";
import crypto from "assets/images/wallet-transactions/crypto.png";
import referral from "assets/images/wallet-transactions/referral.png";
import fee from "assets/images/wallet-transactions/fees.png";
import reward from "assets/images/wallet-transactions/reward.png";
import { TransactionAction, TransactionSource, TransactionType } from 'config/_enums';
import { ASSET_TRANSACTIONS_STATUS, TRANSACTIONS_STATUS, ZERO } from 'config/_const';
import i18n from '18n';

export default class TransactionViewModel {
    private _relatedAsset?: IAssetInAssetTransaction;
    private _transactionDate: Date;
    private _transactionCapital: number;
    private _transactionType: string;
    private _transactionSource: string;
    private tokensAmount: number;
    private _id: string;
    private _action: string;
    private _status: string;

    constructor(transaction: ITransactionInterface) {
        this._relatedAsset = transaction.relatedAsset;
        this._transactionDate = transaction.transactionDate;
        this._transactionCapital = transaction.paid;
        this._transactionType = transaction.type;
        this._transactionSource = transaction.source;
        this.tokensAmount = transaction.amountOfShares || ZERO;
        this._id = transaction.id;
        this._action = transaction.action;
        this._status = transaction.status || '';
    }

    public get RelatedAsset() {
        return this._relatedAsset;
    }

    public get TransactionDate() {
        return this._transactionDate;
    }

    public get TransactionCapital() {
        return this._transactionCapital;
    }
    public get TransactionType(): string {
        return this._transactionType || '';
    }

    public get TransactionSource(): string {
        return this._transactionSource || '';
    }

    public get AmountOfShares() {
        return this.tokensAmount;
    }

    public get Id() {
        return this._id;
    }

    public get Action() {
        switch (this.TransactionType) {
            case TransactionType.WITHDRAW:
            case TransactionType.BUY:
                return TransactionAction.DEBIT;
            case TransactionType.SELL:
                return TransactionAction.CREDIT;
            default:
                return this._action;
        }
    }

    public get Status() {
        return this._status;
    }

    public get TransactionPending() {
        return this._status === TRANSACTIONS_STATUS.PENDING || this._status === TRANSACTIONS_STATUS.OPEN;
    }

    public get HasFailed() {
        return this._status === TRANSACTIONS_STATUS.FAILED || this._status === ASSET_TRANSACTIONS_STATUS.ERROR;
    }

    public get TransactionImage() {
        if (this._relatedAsset)
            return this._relatedAsset.icon;

        switch (this._transactionType) {
            case TransactionType.REWARD:
                return platform;
            case TransactionType.STRIPE:
            case TransactionType.WITHDRAW:
                return credit_card;
            case TransactionType.FIRST_STRIPE_PAYMENT:
                return reward;
            case TransactionType.BITPAY:
                return crypto;
            case TransactionType.REFERRAL:
                return referral;
            case TransactionType.FEE:
                return fee;
            case TransactionType.REGISTER:
                return reward;
            default:
                return platform;

        }
    }

    public get isGiftAssetTransaction() {
        switch (this._transactionSource) {
            case TransactionSource.REGISTER_WITHOUT_REFERRAL:
            case TransactionSource.REGISTER_WITH_REFERRAL:
            case TransactionSource.GIFT_FIRST_STRIPE_PAYMENT:
            case TransactionSource.REWARD:
                return true;
            default:
                return false;
        }
    }

    public get TransactionDetail() {
        switch (this._transactionType) {
            case TransactionType.BUY:
                return this.isGiftAssetTransaction
                    ? i18n.t("wallet.transaction.description.registerGift", { numberOfShares: this.tokensAmount, assetName: this._relatedAsset?.name })
                    : i18n.t("wallet.transaction.description.buy", { numberOfShares: this.tokensAmount, assetName: this._relatedAsset?.name });
            case TransactionType.SELL:
                return i18n.t("wallet.transaction.description.sell", { numberOfShares: this.tokensAmount, assetName: this._relatedAsset?.name });
            case TransactionType.YIELD:
                return i18n.t("wallet.transaction.description.yield", { assetName: this._relatedAsset?.name });
            case TransactionType.REWARD:
                return i18n.t("wallet.transaction.description.reward")
            case TransactionType.REFERRAL:
                return i18n.t("wallet.transaction.description.referral");
            case TransactionType.BITPAY:
                return this._action === TransactionAction.CREDIT ?
                    i18n.t("wallet.transaction.description.crypto.credit") : i18n.t("wallet.transaction.description.crypto.debit");
            case TransactionType.STRIPE:
                return this._action === TransactionAction.CREDIT ?
                    i18n.t("wallet.transaction.description.stripe.credit") : i18n.t("wallet.transaction.description.stripe.debit");
            case TransactionType.WITHDRAW:
                return i18n.t("wallet.transaction.description.stripe.debit");
            case TransactionType.FEE:
                return i18n.t("wallet.transaction.description.fees");
            case TransactionType.MARKETPLACE:
                return i18n.t("wallet.transaction.description.marketPlace");
            case TransactionType.REGISTER:
                return i18n.t("wallet.transaction.description.register");
            case TransactionType.FIRST_STRIPE_PAYMENT:
                return i18n.t("wallet.transaction.description.firstStripePayment");
            case TransactionType.DISCORD_GIVEAWAY:
                return i18n.t("wallet.transaction.description.discordGiveaway");
            case TransactionType.BOOST:
                return i18n.t("wallet.transaction.description.buy.scoreBoost");
            case TransactionType.TOURNAMENT_TICKET:
                return i18n.t("tournament.ticket.buy.transaction");
            default:
                return this._action === TransactionAction.CREDIT ?
                    i18n.t("wallet.transaction.description.credit") : i18n.t("wallet.transaction.description.debit")
        }
    }

    public set AmountOfShares(nb: number) {
        this.tokensAmount = nb;
    }

    public set TransactionCapital(paid: number) {
        this._transactionCapital = paid;
    }

    public asITransaction(): ITransactionInterface {
        return {
            id: this.Id,
            action: this.Action,
            amountOfShares: this.AmountOfShares,
            paid: this.TransactionCapital,
            relatedAsset: this.RelatedAsset,
            status: this.Status,
            source: this.TransactionSource,
            transactionDate: this.TransactionDate,
            type: this.TransactionType,
        }
    }
}

